import * as React from "react";
import '/src/styles/global-styles.css';
import { FormattedMessage, useIntl } from "gatsby-plugin-react-intl";
import { Helmet } from 'react-helmet';
import Layout from "../../components/layout";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import commonMessageValues from '../../templates/formatjs-message-values';

const WhatIsNationalSwitch = () => {
  const m = useIntl().messages;
  const intl = useIntl();
  const images = useStaticQuery(graphql`{
    aps: file(relativePath: {eq: "aps.jpeg"}) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    formatter: allMarkdownRemark(sort: {fields: frontmatter___date}) {
      nodes {
        frontmatter {
          title
          slug
          date
        }
        excerpt
      }
    }
  }`);

  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8"/>
        <title>{m.what_is_national_switch}</title>
      </Helmet>
      <div className="proper-height">
        <GatsbyImage alt={`${m.what_is_national_switch}`} image={images.aps.childImageSharp.gatsbyImageData}/>
      </div>
      <div className={"ui fluid segment vertical container " + (intl.locale === "en" ? "text-ltr" : "text-rtl")}>
        <div className="ui eight wide column" style={{marginTop: '30px'}}>
          <div className="column">
            <FormattedMessage id="what_is_national_switch_p1" values={commonMessageValues}/>
            <div className="ui bulleted list">
              <div className="item">{m.what_is_national_switch_p1_item1}</div>
              <div className="item">{m.what_is_national_switch_p1_item2}</div>
              <div className="item">{m.what_is_national_switch_p1_item3}</div>
              <div className="item">{m.what_is_national_switch_p1_item4}</div>
              <div className="item">{m.what_is_national_switch_p1_item5}</div>
              <div className="item">{m.what_is_national_switch_p1_item6}</div>
              <div className="item">{m.what_is_national_switch_p1_item7}</div>
              <div className="item">{m.what_is_national_switch_p1_item8}</div>
              <div className="item">{m.what_is_national_switch_p1_item9}</div>
            </div>
          </div>
          <br/>
          <div className="column">
            <ol className="ordered-list" start={1}>
              <li className="header ui">{m.what_is_national_switch_heading_item1}</li>
            </ol>
            <div className="ui">
              <FormattedMessage id="what_is_national_switch_heading_item1_p" values={commonMessageValues}/>
            </div>
          </div>
          <br/>
          <div className="column">
            <ol className="ordered-list" start={2}>
              <li className="header ui">{m.what_is_national_switch_heading_item2}</li>
            </ol>
            <div className="ui bulleted list">
              <div className="item">
                <FormattedMessage id="what_is_national_switch_heading_item2_1" values={commonMessageValues}/>
              </div>
              <div className="item">
                <FormattedMessage id="what_is_national_switch_heading_item2_2" values={commonMessageValues}/>
              </div>
              <div className="item">
                <FormattedMessage id="what_is_national_switch_heading_item2_3" values={commonMessageValues}/>
              </div>
            </div>
            <br/>
          </div>
          <div className="column">
            <ol className="ordered-list" start={3}>
              <li className="header ui">{m.what_is_national_switch_heading_item3}</li>
            </ol>
            <div className="ui bulleted list">
              <div className="item">{m.what_is_national_switch_heading_item3_1}</div>
              <div className="item">{m.what_is_national_switch_heading_item3_2}</div>
              <div className="item">{m.what_is_national_switch_heading_item3_3}</div>
              <div className="item">{m.what_is_national_switch_heading_item3_4}</div>
              <div className="item">{m.what_is_national_switch_heading_item3_5}
                <div className="ui bulleted list">
                  <div className="item">{m.what_is_national_switch_heading_item3_5_1}</div>
                  <div className="item">{m.what_is_national_switch_heading_item3_5_2}</div>
                  <div className="item">{m.what_is_national_switch_heading_item3_5_3}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default WhatIsNationalSwitch;